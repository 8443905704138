/* Home.css */
.home {
    position: relative;
    height: 70vh;
}

.home video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-heading {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    font-size: 6rem;
    font-weight: normal;
}

.content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    font-size: 2rem;
    transform: scale(1);
}

@media (max-width: 768px) {
    .container {
        transform: scale(0.8); /* Zoom out for smaller screens */
    }
}

@media (max-width: 480px) {
    .container {
        transform: scale(0.6); /* Further zoom out for even smaller screens */
    }
}

.animated-text {
    animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.buttons {
    /* transform: scale(1);
    margin-right: 5em; */
}

.maintext {
    /* margin-top: 25em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: large; */
}

.maintextword {
    /* padding-left: 0.4em;
    margin-top: 100em; */
}

/* @media (max-width: 768px) {
    .buttons {
        transform: scale(0.8);
    }
    .maintext {
        font-size: smaller;
    }
    .maintextword {
    }
}

@media (max-width: 480px) {
    .buttons {
        transform: scale(0.6);
    }
    .maintext {
        font-size: xx-small;
    }
} */
