body {
    margin: 0;
    /* font-family: 'DM Sans' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
}

:root {
    --primary: #ec3400;
    --white: #ffffff;
    --bg: #121212;
    /* font-family: 'DM Sans' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

/* @media (min-width: 360px) {
    html {
        zoom: 0.25;
    }
}

@media (min-width: 480px) {
    html {
        zoom: 0.4;
    }
}

@media (min-width: 740px) {
    html {
        zoom: 0.75;
    }
} */

@media (min-width: 1440px) {
    html {
        zoom: 1.5;
    }
}

@media (min-width: 2560px) {
    html {
        zoom: 1.7;
    }
}

@media (min-width: 3860px) {
    html {
        zoom: 2.5;
    }
}

::-webkit-scrollbar {
    width: 0.65rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    background: #ec4300;
    transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background: #ec4300;
}

::-webkit-scrollbar-track {
    background: #000000;
}

body {
    font-size: 1.6rem;
    background: var(--bg);
}

.container {
    max-width: 100%;
    padding: 0rem 0rem;
    margin: 0 auto;
}

.heading {
    padding: 1rem 0;
    font-size: 3.5rem;
    text-align: center;
}

.swiper_container {
    width: 100%;
    height: 35rem;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    width: 100%;
    height: 15rem;
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        height: 47rem;
    }

    .swiper-slide {
        width: 10rem !important;
        height: 36rem !important;
        flex-shrink: 2;
    }

    .swiper-slide img {
        width: 10rem !important;
        height: 36rem !important;
    }
}

.swiper-slide img {
    width: 32rem;
    height: 45rem;
    border-radius: 2rem;
    object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: flexbox;
    color: #ec3400;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ec3400;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
    color: #ec3400;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
    color: #ec3400;
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
}

.slider-controler .slider-arrow::after {
    content: "";
    color: #ec3400;
    background-color: #ec3400;
}

.swiper-pagination {
    position: relative;
    width: 1rem !important;
    bottom: 1rem;
    color: #ec3400;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}
