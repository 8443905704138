.withbgimage {
    background-image: url("../Art/aboutbgnew1.jpg");
    filter: brightness(50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 58vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}